import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import ReactDatatable from '@ashvin27/react-datatable';
import toast, { Toaster } from "react-hot-toast";
import config from "../coreFIles/config";
import Cookies from 'js-cookie'
import {getEmaillistAction, EmailDeleteAction } from '../Action/action'
import avt from '../assets/avata_profile.jpg'
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const loginData = (!Cookies.get('loginSuccessBondiAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessBondiAdmin'));
const Emaillisting = () => {
	const [emailList, setEmailList]	= useState([]);
	
	useEffect(() => {
		if (!loginData?.email) {
			window.location.href = `${config.baseUrl}login`
		}
		
		getEmaillist(); 
	}, [])
	
	const getEmaillist = async () => {
		let res = await getEmaillistAction();
		if (res.success) {
			let data = res.data;
			console.log(data);
			setEmailList(data);	
		}
		else
		{
			setEmailList([]);	
		}
	}
	
	const EmailDelete = async (id) => {
		Swal.fire({
			title: 'Are you sure? ',
			text: "You want to Delete this Email template!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
		}).then(async (result) => {
			if (result.isConfirmed) {
				let res = await EmailDeleteAction({'id':id});
				if (res.success) {
					getEmaillist();
					Swal.fire(
						'Deleted!',
						res.msg,
						'success'
					)
				} else {
					Swal.fire(
						'Failed!',
						res.msg,
						'error'
					)
				}
			}
		});
	}
	
	const columns = [
		{
			key: "Sno.",
			text: "#",
			cell: (row, index) => index + 1,
		},
		{
			key: "template_title",
			text: "Temaplate"
		},
	
		{
			key: "domain_id",
			text: "Domain",
			cell: (item) => {
				return (
					<>
						{ item.domain_id === 0 ? 'Bondiclassifieds' : item.title }
					</>
				)
			}
		},
		{
			key: "action",
			text: "Action",
			cell: (item) => {
				return (
					<>
						<div className="btn-group mb-5">
							<Link to={`${config.baseUrl}edit-emailtemplate/` + item.id}>
								<button className='btn btn-sm btn-primary'><i className='fa fa-pencil'></i>  Edit</button>
							</Link>
							&nbsp;&nbsp;&nbsp;
							<button type="button" className="btn btn-danger btn-sm " onClick={() => EmailDelete(item.id)}><i className='fa fa-trash'></i> Delete</button>
						</div>
					</>
				);
			}
		}
	];
	
	const configForTable = {
		page_size: 10,
		length_menu: [10, 20, 50],
		show_filter: true,
		show_pagination: true,
		pagination: "advance",
		button: {
			excel: false,
			print: false,
		},
	};
	
	return (
		<>
			<div className="wrapper">
				<Header />
				<Toaster />
				<Sidebar />
				
				<div className="content-wrapper">
					<div className="container-full">
						{/* Main content */}
						<div className="content-header">
							<div className="d-flex align-items-center">
								<div className="me-auto">
									<h3 className="page-title mb-5 pb-2">Email Template List</h3>
								</div>
							</div>
							<hr />
						</div>
						<section className="content">
							<div className="row">
								<div className="col-lg-12 col-12">
									<div className="box">
										<div className="box-header with-border">
											<div className="row mb-15">
											<h4 className="box-title col-md-3">Email Template List ({emailList.length})</h4>
											<div className="col-md-9 text-right">
											<a href={`${config.baseUrl}add-emailtemplate`} className="btn btn-sm btn-primary add_btn">
												<i className='fa fa-plus'></i> Add New Email Template</a>
											</div>
										</div>
										
										</div>
										<div className="box-body">
										<ReactDatatable
												config={configForTable}
												records={emailList}
												columns={columns}
											/>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>      	
				
				<Footer />
				
			</div>
		</>
	);
}

export default Emaillisting;