const config = {
    baseUrl: '/',   
    //  apiUrl: "http://localhost:3008/api",
	//  imageUrl: 'http://localhost:3008/api/uploads/',
    //  domainImg:'http://localhost:3008/api/domains/',
    //  bannersUrl: 'http://localhost:3008/api/banners/',
    //  mail_images:'http://localhost:3008/api/mail_images/',
    apiUrl : "https://bondi.lyonsdemo.in/apis/api",
    mageUrl: 'https://bondi.lyonsdemo.in/apis/api/uploads/',
    bannersUrl: 'https://bondi.lyonsdemo.in/apis/api/banners/',
    domainImg:'http://bondi.lyonsdemo.in/apis/api/domains/',
    mail_images:'http://bondi.lyonsdemo.in/apis/api/mail_images/',
    superAdmin: 1,
    paging_size: 5
}
export default config;
