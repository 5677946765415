import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import JoditEditor from "jodit-react";
import config from "../coreFIles/config";
import Cookies from 'js-cookie'
import { createEmailtemplateAction, getTemplateTypeAction,getMasterContentAction } from "../Action/action";
import avt from '../assets/avata_profile.jpg'
import { Link } from 'react-router-dom';

const loginData = (!Cookies.get('loginSuccessBondiAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessBondiAdmin'));
const Addemailtemplate = () => {
	const editorHeight = '400px';
	const [spinloader, setspinloader] = useState(0);
	const [domainList, setDomainList] = useState('');
	const [validatioError, setvalidatioError] = useState({});
	const [templateTypeList, setTemplateTypeList] = useState('');
	const [templatedata, settemplatedata] = useState({
		type_id: '',
		domain_id: '',
		subject: '',
		body: '',
	});

	useEffect(() => {
		if (!loginData?.email) {
			window.location.href = `${config.baseUrl}login`
		}
		getTypelist();
		getDomainlist()
	}, [])

	const getTypelist = async () => {
		let res = await getTemplateTypeAction();
		if (res.success) {
			let data = res.data;
			console.log(data);
			setTemplateTypeList(data);
		}
		else {
			setTemplateTypeList([]);
		}
	}
	const getDomainlist = async () => {
			let res = await getMasterContentAction();
			if (res.success) {
				let data = res.data;		
				setDomainList(data.domainlists)
				
			}
			else
			{
				setDomainList([])
			}
		}

	const inputHandler = async (e) => {
		const { name, value } = e.target;
		settemplatedata((old) => {
			return { ...old, [name]: value };
		});
	};

	const descriptionHandler = async (e) => {
		settemplatedata((old) => {
			return { ...old, 'body': e }
		})
	}

	function validate() {
		let subjectError = "";
		let type_idError = "";
		let bodyError = "";

		if (templatedata.subject === "") {
			subjectError = "Subject is required.";
		}

		if (templatedata.type_id === "") {
			type_idError = "Template type is required.";
		}

		if (templatedata.body === "") {
			bodyError = "Template body is required.";
		}


		if (subjectError || type_idError || bodyError) {
			setvalidatioError({
				subjectError, type_idError, bodyError
			});

			return false;
		}
		else {
			setvalidatioError({
				subjectError, type_idError, bodyError
			});
			return true;
		}
	}

	const createEmailtemplate = async (e) => {
		console.log(templatedata)
		e.preventDefault()
		const isValid = validate();
		console.log(isValid);
		if (!isValid) {
		}
		else {
			setspinloader(1);
			let res = await createEmailtemplateAction(templatedata);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}emaillisting`;
				}, 2000);
			}
			else {
				setspinloader(0);
				toast.error(res.msg);
			}
		}
	}

	return (
		<>
			<div className="wrapper">
				<Header />
				<Toaster />
				<Sidebar />
				<div className="content-wrapper">
					<div className="container-full">
						<div className="content-header">
							<div className="d-flex align-items-center">
								<div className="me-auto">
									<h3 className="page-title mb-5 pb-2">Add New Email Template</h3>
								</div>
							</div>
							<hr />
						</div>
						{/* Content Header (Page header) */}

						{/* Main content */}
						<section className="content">
							<div className="row">
								<div className="col-lg-12 col-12">
									<div className="box">
										<div className="box-header with-border">
											<h4 className="box-title">Add New Email Template</h4>
											<a href={`${config.baseUrl}emaillisting`} className="btn btn-sm btn-primary add_btn">Back</a>
										</div>
										<div className="row mt-20 mb-50 ml-15 mr-15">
											<form onSubmit={createEmailtemplate}>
												<div className="col-md-12">
													<div className="row">
														<div className="col-md-4">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																	Domain <span className="req-star">*</span>
																</label>
																<div className="col-md-12">
																	<select name='domain_id' className="form-control" id='domain_idError' onChange={inputHandler}>
																		<option value="">Select</option>
																		{domainList.length > 0 ?
																			domainList.map(domain => (
																				<option value={domain.id}>{domain.title}</option>
																			)) : ''
																		}
																	</select>
																	<span className="validationErr">
																		{validatioError.domain_idError}
																	</span>
																</div>
															</div>
														</div>
														<div className="col-md-4">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																	Template Type <span className="req-star">*</span>
																</label>
																<div className="col-md-12">
																	<select name='type_id' className="form-control" id='type_idError' onChange={inputHandler}>
																		<option value="">Select</option>
																		{templateTypeList.length > 0 ?
																			templateTypeList.map(type => (
																				<option value={type.id}>{type.system_keyword}</option>
																			)) : ''
																		}

																	</select>
																	<span className="validationErr">
																		{validatioError.type_idError}
																	</span>
																</div>
															</div>
														</div>
														<div className="col-md-4">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																	Subject <span className="req-star">*</span>
																</label>
																<div className="col-md-12">
																	<input className="form-control" type="text" name="subject" id='subjectError' onChange={inputHandler} placeholder="Enter title" />
																</div>
																<span className="validationErr">
																	{validatioError.subjectError}
																</span>
															</div>
														</div>

														<div className="col-md-12">
															<div className="form-group row mb-1">
																<label className="col-form-label col-md-12">Description <span className="req-star">*</span> </label>
																<div className="col-md-12">
																	<JoditEditor
																		onChange={descriptionHandler}
																		value=''
																		name="body"
																		id="bodyError"
																		style={{ height: editorHeight }}
																		className="form-control"
																		placeholder="Enter description"
																	/>
																</div>
																<span className="validationErr">
																	{validatioError.bodyError}
																</span>
															</div>
														</div>

														<div className="col-md-12">

															<br />
															<div className="text-center pull-left">
																{spinloader == '0' ?
																	<button className='btn btn-primary'>Submit</button>
																	:
																	<button disabled className='btn btn-primary'>Submiting <i className="fa fa-spinner fa-spin validat"></i></button>
																}
															</div>
														</div>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</section>
						{/* /.content */}
					</div>
				</div>

				<Footer />
			</div>
		</>
	);
};
export default Addemailtemplate;